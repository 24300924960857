<template>
  <v-container data-app :retain-focus="false">
    <v-row>
      <v-col>
        <v-card>
          <v-card-actions class="py-0 order_actions" style="display: block" v-if="!!order_id">
            <v-btn style="background: #eee !important;" @click="$router.push('/orders')">
              <v-icon>mdi-arrow-left</v-icon>
              Назад
            </v-btn>
            <v-btn :loading="loading_save" @click="updateOrder(()=>loading_save = false);">
              <v-icon>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
            <v-btn :loading="loading_save_exit"
                   @click="updateOrder(()=>{$router.push('/orders'); loading_save_exit = false;loading_save = false;});">
              <v-icon>mdi-content-save-move-outline</v-icon>
              Сохранить и выйти
            </v-btn>
            <v-btn :href="`#/orders/${order_id}?copyOrder=1`" target="_blank" style="background: #eee !important">
              <v-icon v-text="'mdi-content-copy'"/>
              Создать копию
            </v-btn>
            <template v-if="order.orderStatus !== '5'">
              <v-btn :loading="loading_confirm" v-if="order.orderStatus === '6'" class="v-btn-success"
                     @click="loading_confirm = !loading_confirm;updateOrder(()=>confirm())">
                <v-icon>mdi-check</v-icon>
                Подтвердить
              </v-btn>
              <v-btn :loading="loading_checkout" v-if="order.orderStatus === '0'" class="v-btn-success"
                     @click="updateOrder(()=>request(`order/checkout/${order_id}`, {}, 'Заказ перешёл в статус оформлен')); loading_checkout = !loading_checkout">
                <v-icon>mdi-check</v-icon>
                Оформлен
              </v-btn>
              <v-btn v-if="order.orderStatus !== '8'" class="v-btn-warning" @click="openToolbarModal('problem')">
                <v-icon>mdi-alert</v-icon>
                Проблема
              </v-btn>
              <v-btn v-else class="v-btn-success" @click="updateOrder(()=>problemSolve())">
                <v-icon>mdi-check-circle-outline</v-icon>
                Проблема решена
              </v-btn>
              <v-btn v-if="!parseInt(order.waiting)" class="v-btn-warning" @click="updateOrder(()=>setWaiting())">
                <v-icon>mdi-alert</v-icon>
                На ожидание
              </v-btn>
              <v-btn v-else class="v-btn-success" @click="updateOrder(()=>unsetWaiting())">
                <v-icon>mdi-check-circle-outline</v-icon>
                Снять с ожидания
              </v-btn>
              <v-btn v-if="order.orderStatus !== '4'" class="v-btn-danger" @click="openToolbarModal('remove')">
                <v-icon>mdi-delete</v-icon>
                Удалить
              </v-btn>
              <v-btn v-if="order.orderStatus === '4'" class="v-btn-danger" @click="returnFromTrash">
                <v-icon>mdi-delete-off</v-icon>
                Вернуть из корзины
              </v-btn>
              <v-btn v-if="order.orderStatus !== '8' && order.orderStatus !== '4' && order.orderStatus !== '5'"
                     class="v-btn-info" @click="openToolbarModal('pay')">
                <v-icon>mdi-credit-card-outline</v-icon>
                Оплата
              </v-btn>
            </template>
            <v-btn @click="openToolbarModal('history')">
              <v-icon>mdi-format-list-bulleted</v-icon>
              История
            </v-btn>
            <v-btn class="v-btn-success" @click="openToolbarModal('review')">
              <v-icon>mdi-message-alert</v-icon>
              Оценка
            </v-btn>
            <v-btn class="v-btn-info" @click="$eventBus.$emit('openTask', {id: 0, order_id: order.n_id})">
              <v-icon>mdi-calendar-check</v-icon>
              Добавить задачу по заказу
            </v-btn>
            <v-btn class="v-btn-info" @click="openToolbarModal('houseMap')">
              <v-icon>mdi-map</v-icon>
              Карта дома
            </v-btn>
            <v-btn
                @click="$eventBus.$emit('sendSMS', order.client, `${order.client.name}, мы не смогли с вами связаться для уточнения вашего заказа на уборку. Перезвоните нам:${$store.state.user.city.phone}. Ежедневно с ${$store.state.user.city.worktime_from} до ${$store.state.user.city.worktime_to}`)">
              <v-icon>mdi-email-send-outline</v-icon>
              Отправить SMS клиенту
            </v-btn>
            <v-btn v-if="!parseInt(order.reorder)" class="v-btn-danger" :loading="loading_reorder" @click="reorder">
              <v-icon>mdi-reload-alert</v-icon>
              Создать переуборку
            </v-btn>
            <v-btn @click="openToolbarModal('toOtherCity')">
              Отправить в другой город
            </v-btn>
            <v-chip color="primary" style="background-color: #1867c0 !important;margin-top: 8px;margin-left: 8px"
                    v-if="parseInt(order.datetime_clean_end)">
              Выполнен с {{ order.datetime_clean_startObj.time }} по {{ order.datetime_clean_endObj.time }}
              ({{ parseInt((order.datetime_clean_end - order.datetime_clean_start) / 3600) }} ч
              {{ parseInt((order.datetime_clean_end - order.datetime_clean_start) / 60) % 60 }} мин)
            </v-chip>
            <v-btn class="ml-2 v-btn-info" @click="$refs.act.open(order.n_id)"
                   v-if="order.showAct">
              {{ order.sign ? 'Открыть акт' : 'Акт не был подписан' }}
            </v-btn>
            <v-btn class="ml-2" v-if="order.check_link" :href="order.check_link" target="_blank">
              Чек
            </v-btn>
            <v-btn class="ml-2" v-if="order.paymentLink" @click="paymentLinkModalOpened = true">
              Ссылка на оплату
            </v-btn>
            <v-dialog data-app v-model="paymentLinkModalOpened" max-width="400px">
              <v-card>
                <v-icon class="btn-close-modal" @click="paymentLinkModalOpened=false" size="36">mdi-close</v-icon>
                <v-card-title>Ссылка на оплату заказа</v-card-title>
                <v-card-text>
                  <v-text-field ref="copyLink" readonly :value="order.paymentLink"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn v-if="copied">
                    <v-icon>mdi-check</v-icon>
                    Ссылка скопирована
                  </v-btn>
                  <v-btn v-else @click="copyLink">Скопировать</v-btn>
                  <v-btn style="background: #eee !important;" @click="paymentLinkModalOpened = false">Закрыть</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
          <v-card-actions v-else>
            <v-btn @click="updateOrder">
              <v-icon>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="order.reorder || order.original">
      <v-col>
        <v-card>
          <v-card-text>
            <v-row v-if="order.reorder">
              <v-alert type="warning" outlined>
                У этого заказа есть переуборка:
                <router-link :to="`/orders/${order.reorder}`" custom v-slot="{ navigate }">
                  <a @click="navigate" @keypress.enter="navigate" role="link">Заказ №{{ order.reorder }}</a>
                </router-link>
              </v-alert>
            </v-row>
            <v-row v-if="order.original">
              <v-alert type="warning" outlined>
                Это переуборка заказа
                <router-link :to="`/orders/${order.original}`" custom v-slot="{ navigate }">
                  <a @click="navigate" @keypress.enter="navigate" role="link">Заказ №{{ order.original }}</a>
                </router-link>
              </v-alert>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-form
        v-model="valid"
        ref="form">
      <v-row v-if="order_id && (order.cleanerPhotos && order.cleanerPhotos.length > 0)">
        <v-col>
          <v-row>
            <v-card>
              <v-card-title>Фотографии</v-card-title>
              <v-card-text>
                <viewer :images="order.cleanerPhotos">
                  <img
                      v-for="photo in order.cleanerPhotos"
                      :src="photo.src"
                      style="width: 200px; height: 200px; object-fit: cover;margin: 0 5px 5px 0"
                      alt="avatar">
                </viewer>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="tasks.length">
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>
              Задачи по заказу
            </v-card-title>
            <v-card-text class="pb-0">
              <ol>
                <li v-for="task in tasks">
                  <a @click="$eventBus.$emit('openTask', {id: task.id})">{{ task.title }}</a>
                </li>
              </ol>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title style="justify-content: space-between;">
              Основное
              <v-btn @click="$refs.OrderToLead.open(order_id)"
                     v-if="order_id && +order.orderStatus===0 && $store.state.newLeads()" class="v-btn-danger">Не
                заказал
              </v-btn>
              <OrderToLead ref="OrderToLead"/>
            </v-card-title>
            <v-card-text>
              <v-row v-if="order.comment_client" class="row-d-block">
                <v-subheader>Коммент клиента к заказу</v-subheader>
                <v-row>
                  <v-textarea disabled :value="order.comment_client" outlined/>
                </v-row>
              </v-row>
              <v-row v-if="order.comment" class="row-d-block">
                <v-subheader>Коммент к заказу</v-subheader>
                <v-row>
                  <v-textarea rows="8" disabled :value="order.comment" outlined/>
                </v-row>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Телефон <span style="color: red">*</span></v-subheader>
                <v-row>
                  <v-text-field
                      v-model="order.client.phone"
                      type="tel"
                      id="phone_input"
                      @change="findByPhone"
                      required
                      :rules="[v=>(v && v.replace(/\D/g, '').length > 10) || 'Укажите номер']"
                      outlined
                      v-mask="getMask(order.client.phone)"
                  />
                  <div style="cursor: pointer;padding: 5px 15px;"
                       :disabled="!order.client.phone">
                    <a :href="'tel: ' + order.client.phone">Позвонить</a>
                  </div>
                </v-row>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Имя <span style="color: red">*</span></v-subheader>
                <v-row>
                  <v-text-field
                      v-model="order.client.name"
                      type="text"
                      :rules="[v => !!v || 'Укажите имя']"
                      required
                      outlined
                      style="align-items: baseline;"
                  />
                  <div style="cursor: pointer;padding: 5px 15px;"
                       @click="$eventBus.$emit('openClient', order.client.n_id)"
                       :disabled="!order.client.n_id">Открыть
                  </div>
                </v-row>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Реклама <span style="color: red">*</span></v-subheader>
                <span
                    v-if="$store.state.user.adv && order.utm_source && !+order.client.adv && !$store.state.user.adv.find(adv=>adv.utm === order.utm_source)">
                  Есть utm: {{ order.utm_source }}</span>
                <div v-else style="display: flex">
                  <v-select
                      v-model="order.client.adv"
                      dense
                      :items="(+order.client.adv && !$store.state.user.adv.find(a=>a.n_id==order.client.adv)) ? $store.state.user.adv.concat([$store.state.user.advAll.find(a=>a.n_id==order.client.adv)]):$store.state.user.adv"
                      placeholder="Не определено"
                      item-text="title"
                      item-value="n_id"
                      :rules="[v=>!!parseInt(v) || 'Выберите рекламу']"
                      outlined
                      required/>
                  <v-btn icon class="ml-1" height="33" @click="$refs.advSettings.open()">
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </div>
              </v-row>
              <v-row class="row-d-block" v-if="$store.state.user.city.country.lang!=='ru'">
                <v-subheader>Язык, на котором говорит клиент</v-subheader>
                <v-select
                    v-model="order.client.lang"
                    dense
                    :items="$store.state.toSelectItems($store.state.user.languages ? $store.state.user.languages : [], 'title', 'code')"
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Регулярность заказа
                  <InfoTip
                      value="При оплате заказа CRM сама<br> создаст следующий заказ<br> на нужную дату и он упадёт во Входящие"></InfoTip>
                  <v-chip color="orange" text-color="white" small>NEW</v-chip>
                </v-subheader>
                <v-select
                    v-model="order.subscribe"
                    dense
                    :items="regularTypes"
                    item-text="name"
                    item-value="value"
                    outlined
                    required/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>О клиенте</v-subheader>
                <v-textarea
                    v-model="order.client.text"
                    outlined/>
              </v-row>
              <v-row class="mt-2">
                <v-subheader class="mr-2">Статус:</v-subheader>
                <strong style="display: flex; align-items: center">{{ order.orderStatusString }}</strong>
                <v-btn @click="cancelPayment" small style="background: red !important;color: white !important;"
                       class="ml-4" v-if="+order.orderStatus===5 && $store.state.user.superAdmin">Отменить оплату
                </v-btn>
              </v-row>
              <v-row class="row-d-block" v-if="+order.orderStatus===4">
                <v-subheader>Причина</v-subheader>
                <v-textarea
                    v-model="order.comment_cancel" readonly
                    outlined/>
              </v-row>
              <v-row>
                <v-subheader class="mr-2">Заказов:</v-subheader>
                <span v-if="orderStats"
                      style="display: flex; align-items: center">
                   {{ orderStats.cnt }} шт на сумму {{
                    orderStats.sum
                  }} {{
                    $store.state.user.city.country.currency
                  }} (ср чек {{
                    parseInt(orderStats.sum / orderStats.cnt) || 0
                  }} {{ $store.state.user.city.country.currency }})
                 </span>
              </v-row>
              <v-row style="display: flex; align-items: center">
                <v-subheader class="mr-2">Последняя оценка:</v-subheader>
                <span v-if="lastFeedback">
                  <v-rating
                      style="display: inline-block;vertical-align: middle;padding-bottom: 5px;"
                      :value="parseInt(lastFeedback.rate)"
                      class="rating"
                      readonly
                      size="16"
                      dense
                  />
                  {{ $root.dateToRus(lastFeedback.datetime.date) }}
                    <span v-for="(cleaner, index) in lastFeedback.order.cleaners"
                          :key="index">{{ cleaner.name }} </span>
                    </span>
                <span v-else>---</span>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader class="mr-2">Заказ оформлен через:</v-subheader>
                <v-select v-model="order.source_from" :items="$store.state.sourcesFrom" outlined
                          item-value="value" item-text="text" :disabled="$route.query.source_from"/>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Адрес клиента</v-card-title>
            <v-card-text>
              <v-row class="row-d-block">
                <v-subheader>Адреса</v-subheader>
                <v-select
                    v-model="order.address.n_id"
                    outlined
                    :items="addressesOpt.concat({label: 'Новый адрес', value: '0'})"
                    item-text="label"
                    item-value="value"
                    no-data-text="Адресов нет"
                    @change="selectAddress"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Регион</v-subheader>
                <v-select
                    v-model="order.address.region"
                    outlined
                    no-data-text="Регионов нет"
                    :items="$store.state.user.regions"
                    item-text="title"
                    item-value="n_id"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Улица <span style="color: red">*</span></v-subheader>
                <v-autocomplete
                    :loading="loading_street"
                    v-model="order.address.street"
                    :items="streets"
                    hide-no-data
                    item-value="street"
                    item-text="street"
                    :no-filter="true"
                    @change="loadMetro"
                    :search-input.sync="searchStreets"
                    outlined
                    required
                    @blur="order.address.street = searchStreets"
                />
              </v-row>
              <v-row>
                <v-col>
                  <v-row class="row-d-block">
                    <v-subheader>Дом <span style="color: red">*</span></v-subheader>
                    <v-text-field
                        v-model="order.address.house"
                        type="text"
                        outlined
                        @change="loadMetro"
                        required/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Стр.</v-subheader>
                    <v-text-field
                        v-model="order.address.building"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Этаж</v-subheader>
                    <v-text-field
                        v-model="order.address.floor"
                        outlined/>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="row-d-block">
                    <v-subheader>Корп.</v-subheader>
                    <v-text-field
                        v-model="order.address.corp"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Подъезд</v-subheader>
                    <v-text-field
                        v-model="order.address.podiezd"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Квартира</v-subheader>
                    <v-text-field
                        v-model="order.address.flat"
                        outlined/>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Домофон</v-subheader>
                <v-text-field
                    v-model="order.address.code"
                    outlined/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Комментарий к адресу</v-subheader>
                <v-textarea
                    v-model="order.address.comment"
                    :rows="2"
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Метро</v-subheader>
                <v-text-field
                    v-model="order.address.metro"
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Линия метро</v-subheader>
                <v-text-field
                    v-model="order.address.metroLine"
                    outlined
                />
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mobile-col-basis">
          <v-row>
            <v-col style="padding: 0 !important;">
              <v-card>
                <v-card-title>
                  <div>Детали заказа</div>
                </v-card-title>
                <v-card-text>
                  <v-row class="row-d-block">
                    <v-subheader>Дата уборки <span style="color: red">*</span></v-subheader>
                    <v-row>
                      <v-col style="padding-left: 0 !important;">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                outlined
                                :value="order.datetimecleanObj ? $root.dateToRus(order.datetimecleanObj.date) : ''"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            />
                          </template>
                          <v-date-picker
                              v-model="order.datetimecleanObj.date"
                              no-title
                              scrollable
                              first-day-of-week="1"
                              locale="ru"
                              @change="calculate"
                              :reactive="true"
                              @input="menu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col style="padding-right: 0 !important;">
                        <v-text-field
                            v-model="order.datetimecleanObj.time"
                            v-mask="'##:##'"
                            required
                            outlined
                            v-debounce:300ms.lock="calculate"
                        />
                      </v-col>
                      <v-col style="padding-right: 0 !important;">
                        <v-select v-if="$store.state.user && $store.state.user.orderTimeSlots"
                                  placeholder="Слоты" @change="setTime" @click="updateSlots" outlined
                                  :items="$store.state.user.orderTimeSlots.filter(t=>Object.keys(slotsInfo.time_stats).length)">
                          <template v-slot:item="{item}">
                            {{ item }}
                            <CleanerSlotsUsage class="ml-2"
                                               :total-cleaners="slotsInfo.time_stats[+(item.split(':')[0])].cleaners"
                                               :need-cleaners="slotsInfo.time_stats[+(item.split(':')[0])].needCleaners"/>
                            <span class="ml-2">{{
                                slotsInfo.coefs[+(item.split(':')[0])] ? Math.round((slotsInfo.coefs[+(item.split(':')[0])][order.type] - 1) * 100) + '%' : ''
                              }}</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row v-if="initialOrder.koef && +initialOrder.koef!==+order.koef" class="row-d-block">
                    <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                    >
                      Повышенный тариф изменится с {{ initialOrder.koef }} на {{ order.koef }}. При необходимости
                      поменяйте тариф вручную:
                      <v-radio-group v-model="koefManual">
                        <v-radio
                            label="Автоматический расчёт тарифа"
                            :value="0"
                        ></v-radio>
                        <v-radio
                            label="Указать вручную"
                            :value="1"
                        ></v-radio>
                      </v-radio-group>
                      <v-text-field v-if="koefManual" @change="calculate" outlined v-model="order.koefFixed"/>
                    </v-alert>
                    <div></div>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Кол-во комнат</v-subheader>
                    <v-select
                        v-model="order.address.flattype"
                        :items="rooms"
                        item-text="label"
                        item-value="value"
                        placeholder="Выберите кол-во комнат"
                        @change="calculate"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Пояснения</v-subheader>
                    <v-textarea
                        v-model="order.address.flattypeInfo"
                        :rows="2"
                        placeholder="Текст пояснения"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Тип</v-subheader>
                    <v-select
                        v-model="order.type"
                        :items=cleanTypes
                        placeholder="Выберите тип уборки"
                        item-value="value"
                        item-text="label"
                        @change="calculate"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>М<sup>2</sup></v-subheader>
                    <v-text-field
                        v-model="order.address.sqr"
                        type="number"
                        min="0"
                        v-debounce:300ms.lock="calculate"
                        outlined/>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-subheader>Санузлы</v-subheader>
                    </v-col>
                    <v-col>
                <span class="services_step_button" :class="$root.intval(order.address.wcNum)===0 ? 'disabled' : ''"
                      @click="order.address.wcNum = $root.intval(order.address.wcNum)-1;calculate()">
                  <v-icon>
                    mdi-minus
                  </v-icon>
                </span>
                      <v-text-field
                          style="width: 50px;display: inline-block"
                          v-model="order.address.wcNum"
                          v-debounce:300ms.lock="calculate"
                          type="number"
                          class="no_arrows"
                          autocomplete="off"
                          outlined
                      />
                      <span class="services_step_button"
                            @click="order.address.wcNum = $root.intval(order.address.wcNum)+1;calculate()">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-subheader>Балконы</v-subheader>
                    </v-col>
                    <v-col>
                <span class="services_step_button" :class="$root.intval(order.balNum)===0 ? 'disabled' : ''"
                      @click="order.balNum = $root.intval(order.balNum)-1;calculate()">
                  <v-icon>
                    mdi-minus
                  </v-icon>
                </span>
                      <v-text-field
                          style="width: 50px;display: inline-block"
                          v-model="order.balNum"
                          v-debounce:300ms.lock="calculate"
                          type="number"
                          class="no_arrows"
                          autocomplete="off"
                          outlined
                      />
                      <span class="services_step_button"
                            @click="order.balNum = $root.intval(order.balNum)+1;calculate()">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="padding: 10px 0 0 0 !important;">
              <v-card>
                <v-card-title>
                  <v-row class="row-d-block">
                    Химчистка
                    <v-checkbox
                        v-model="order.himchistka"
                        @change="onDryCleaningChange"
                        true-value="1"
                        false-value="0"
                        style="display: inline-block;vertical-align: middle;"
                        label=""
                    />
                  </v-row>
                </v-card-title>
                <v-card-text
                    :style="+order.himchistka ? '':'opacity:0.5;pointer-events: none;height: 10px;overflow:hidden'">
                  <div style="display: flex;justify-content: flex-end;margin-bottom: 10px;flex-direction: column;"
                       v-for="(service, fieldName) in $store.state.user.services.dry" :key="fieldName">
                    <div style="display: flex;">
                      <v-subheader style="flex:1">{{ service.label }}</v-subheader>
                      <div><span class="services_step_button"
                                 :class="serviceObject(fieldName).count===0 ? 'disabled' : ''"
                                 @click="setServiceObject(fieldName, serviceObject(fieldName).count-1);">
                  <v-icon>
                    mdi-minus
                  </v-icon>
                </span>
                        <v-text-field
                            style="width: 50px;display: inline-block"
                            :value="serviceObject(fieldName).count"
                            type="number"
                            @change="setServiceObject(fieldName, $event);"
                            class="no_arrows"
                            autocomplete="off"
                            outlined
                        />
                        <span class="services_step_button"
                              @click="setServiceObject(fieldName, serviceObject(fieldName).count+1);">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </span>
                      </div>
                    </div>
                    <div v-if="serviceObject(fieldName).count"
                         style="display: flex;justify-content: space-evenly;margin-top: 10px;">
                      <v-checkbox
                          v-for="(title, name) in {him_koja:'Кожа',him_jivotnie:'Шерсть',him_smell:'Запах',}"
                          :key="name"
                          @change="setServiceObject(fieldName, $event, name)"
                          true-value="1"
                          false-value="0"
                          :label="title"
                          :input-value="serviceObject(fieldName)[name]"
                      ></v-checkbox>
                    </div>
                  </div>
                  <v-row class="row-d-block">
                    <v-subheader>Дополнительно</v-subheader>
                    <v-text-field
                        v-model="order.him_dop"
                        v-debounce:300ms.lock="calculate"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Итого за химчистку</v-subheader>
                    <v-text-field
                        v-model="order.him_total"
                        readonly
                        outlined/>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Дополнительные услуги</v-card-title>
            <v-card-text v-if="$store.state.user && $store.state.user.services">
              <v-row v-for="(service, fieldName) in $store.state.user.services.services" :key="fieldName">
                <v-col>
                  <v-subheader>{{ service.label }}</v-subheader>
                </v-col>
                <v-col>
                <span class="services_step_button" :class="$root.intval(order[fieldName])===0 ? 'disabled' : ''"
                      @click="order[fieldName] = $root.intval(order[fieldName])-1;calculate()">
                  <v-icon>
                    mdi-minus
                  </v-icon>
                </span>
                  <v-text-field
                      style="width: 50px;display: inline-block"
                      v-model="order[fieldName]"
                      v-debounce:300ms.lock="calculate"
                      type="number"
                      class="no_arrows"
                      autocomplete="off"
                      outlined
                  />
                  <span class="services_step_button"
                        @click="order[fieldName] = $root.intval(order[fieldName])+1;calculate()">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </span>
                </v-col>
              </v-row>
              <v-row v-for="(check_service, fieldName) in $store.state.user.services.check_services" :key="fieldName">
                <v-col>
                  <v-subheader>{{ check_service.label }}</v-subheader>
                </v-col>
                <v-col style="text-align: right">
                  <v-checkbox
                      v-model="order[fieldName]"
                      @change="checkboxClicked(fieldName);calculate()"
                      true-value="1"
                      false-value="0"
                      dense
                      label=""/>
                </v-col>
              </v-row>
              <v-row v-for="(comment, fieldName) in comments" :key="fieldName"
                     class="row-d-block"
              >
                <v-subheader>{{ comment.label }}</v-subheader>
                <v-textarea
                    v-model="order[fieldName]"
                    :rows="2"
                    placeholder=""
                    outlined
                    :readonly='comment.disabled'/>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="order_id">
        <v-col>
          <v-card>
            <v-card-title>Клинеры</v-card-title>
            <v-card-text>
              <div>
                <v-chip class="cleaner"
                        label
                        color="primary"
                        style="margin: 0 10px 10px 0;background-color: #1867c0 !important;"
                        v-for="cleaner in order.cleaners" :key="cleaner.n_id"
                        @click="$eventBus.$emit('openCleaner', cleaner.n_id)">
                  <v-icon style="margin-right: 5px" v-if="cleaner.status === '1'" size="16">mdi-email-outline</v-icon>
                  <v-icon style="margin-right: 5px" v-if="cleaner.status === '2'" size="16">mdi-check</v-icon>
                  {{ cleaner.name }}
                </v-chip>
              </div>
              <div v-if="[0,1,6,7,8,9, 2].includes(parseInt(order.orderStatus))">
                <v-btn @click="openCleaners" class="mr-3">

                  Изменить
                </v-btn>
                <v-btn v-if="order.cleaners && order.cleaners.length" @click="sendNotification">
                  <v-icon>mdi-email-send</v-icon>
                  Отправить клинерам
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="0">
        <v-col>
          <v-card>
            <v-card-title>Предложите следующие доп. услуги</v-card-title>
            <v-card-text>
              <v-list style="width: 300px;">
                <v-list-item style="display: flex;justify-content: space-between;">
                  <div>Мытьё окон</div>
                  <v-btn>+ Добавить</v-btn>
                </v-list-item>
                <v-list-item style="display: flex;justify-content: space-between;">
                  <div>Уборка балкона</div>
                  <v-btn>+ Добавить</v-btn>
                </v-list-item>
                <v-list-item style="display: flex;justify-content: space-between;">
                  <div>Особые поручения</div>
                  <v-btn>+ Добавить</v-btn>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Оплата</v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="mobile-col-basis">
                  <v-row v-for="(discount, fieldName) in discounts" :key="fieldName"
                         class="row-d-block">
                    <v-subheader>{{ discount.label }} (в %)</v-subheader>
                    <v-text-field
                        v-model="order[fieldName]"
                        v-debounce:300ms.lock="calculate"
                        max="100"
                        min="0"
                        type="number"
                        outlined/>
                  </v-row>
                  <v-row>
                    <v-subheader>Бесплатное мытьё окон</v-subheader>
                    <v-checkbox
                        v-model="order.freeWindow"
                        v-debounce:300ms.lock="calculate"
                        true-value="1"
                        false-value="0"
                        dense
                        label=""/>
                  </v-row>
                  <v-row>
                    <v-subheader>Промо уборка</v-subheader>
                    <v-checkbox
                        v-model="order.promoClean"
                        @click="calculate"
                        true-value="1"
                        false-value="0"
                        dense
                        label=""/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Промокод или код сертификата</v-subheader>
                    <div class="d-flex align-center">
                      <v-text-field v-model="order.promo" outlined
                                    v-debounce:300ms.lock="calculate"/>
                      <v-btn v-if="order.promo"
                          @click="applyPromo" class="ml-2" small>Применить</v-btn>
                    </div>
                    <div class="promo-info">
                      <span v-if="order.promo && calcData.promoInfo && calcData.promoInfo.title"
                      style="color: green">
                        {{calcData.promoInfo.title}}
                      </span>
                    </div>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Скидка в руб</v-subheader>
                    <v-text-field
                        v-model="order.discountPromo"
                        v-debounce:300ms.lock="calculate"
                        outlined/>
                  </v-row>
                </v-col>
                <v-col class="mobile-col-basis">
                  <v-row class="row-d-block">
                    <v-subheader>Способ оплаты</v-subheader>
                    <v-select
                        v-model="order.paymentType"
                        @change="calculate"
                        :items="paymentTypes"
                        item-text="label"
                        item-value="value"
                        placeholder="Выберите способ оплаты"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Оплачено бонусами</v-subheader>
                    <v-text-field
                        v-model="order.bonus"
                        v-debounce:300ms.lock="calculate"
                        type="number"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Дополнительные услуги (руб)</v-subheader>
                    <v-text-field
                        v-model="order.additional"
                        v-debounce:300ms.lock="calculate"
                        type="number"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Такси</v-subheader>
                    <v-text-field
                        v-model="order.taxi"
                        v-debounce:300ms.lock="calculate"
                        type="number"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Такси оплачивает клиент</v-subheader>
                    <v-checkbox
                        v-model="order.taxiClient"
                        @change="calculate"
                        true-value="1"
                        false-value="0"
                        dense
                        label=""/>
                  </v-row>
                  <v-row v-if="order.type==='other'" class="row-d-block">
                    <v-subheader>Фиксированная стоимость</v-subheader>
                    <v-text-field
                        @change="calculate"
                        v-model="order.totalCostFixed"
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Стоимость услуг</v-subheader>
                    <v-text-field
                        v-model="order.totalCost"
                        readonly
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>Коэффициент тарифа</v-subheader>
                    <v-text-field
                        v-model="order.koef"
                        readonly
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>К оплате</v-subheader>
                    <v-text-field
                        v-model="order.totalPay"
                        readonly
                        outlined/>
                  </v-row>
                  <v-row class="row-d-block">
                    <v-subheader>В кассу</v-subheader>
                    <v-text-field
                        v-model="order.total"
                        readonly
                        outlined/>
                  </v-row>
                  <v-row v-if="!!order_id" style="padding-top: 8px">
                    <v-btn v-if="order.orderStatus !== '8' && order.orderStatus !== '4' && order.orderStatus !== '5'"
                           @click="openToolbarModal('pay')">
                      <v-icon>mdi-credit-card-outline</v-icon>
                      Оплата
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <Remove ref="remove"/>
      <Problem ref="problem"/>
      <Payment ref="pay"/>
      <Review ref="review"/>
      <History ref="history"/>
      <HouseMap ref="houseMap"/>
      <ToOtherCity ref="toOtherCity"/>
      <Cleaners ref="cleaners"/>
      <Act ref="act"/>
      <AdvSettings ref="advSettings"/>
    </v-form>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <div class="form-actions">
              <v-btn class="mr-2" style="background: #eee !important;" @click="$router.push('/orders')">
                <v-icon>mdi-arrow-left</v-icon>
                Назад
              </v-btn>
              <v-btn class="mr-2 mb-2" v-if="!!order_id" :loading="loading_save"
                     @click="updateOrder(()=>loading_save = false);">
                <v-icon>mdi-content-save-outline</v-icon>
                Сохранить
              </v-btn>
              <v-btn class="mb-2" v-if="!!order_id" :loading="loading_save_exit"
                     @click="updateOrder(()=>$router.push('/orders'));">
                <v-icon>mdi-content-save-move-outline</v-icon>
                Сохранить и выйти
              </v-btn>
              <v-btn v-else :loading="loading_save" @click="updateOrder">
                <v-icon>mdi-content-save-outline</v-icon>
                Сохранить
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card
        style="max-height: 150px; position: sticky; bottom: -5px;margin: 0 8px;width: calc(100% - 16px);box-shadow: 0px 0px 5px 2px #aaa;">
      <v-card-text
          style="padding: 5px 10px 10px 10px;display: flex;gap: 20px;justify-content: space-evenly;flex-wrap: wrap;">
        <div>
          {{ $vuetify.breakpoint.width > 480 ? 'Тип: ' : '' }}{{
            cleanTypes.find(el => el.value === order.type).label
          }}
        </div>
        <div>
          Комнат: {{
            rooms.find(el => el.value === order.address.flattype) ? rooms.find(el => el.value === order.address.flattype).label : rooms[0].label
          }}
        </div>
        <div>
          Доп:
          {{
            order.additionServices && parseInt(order.totalCost) ? parseInt(Math.round(order.totalCost - (order.additionServices.clean + (order.additionServices.him || 0)) || 0)) : 0
          }}
          {{ $store.state.user.city.country.currency }}
        </div>
        <div v-if="+order.additionServices.him">
          Химчистка:
          {{
            Math.round(order.additionServices.him)
          }}
          {{ $store.state.user.city.country.currency }}
        </div>
        <div>
          {{ $vuetify.breakpoint.width > 480 ? 'Сумма заказа: ' : 'Сум: ' }}{{ parseInt(order.totalCost) || 0 }}
          {{
            $store.state.user.city.country.currency
          }}
        </div>
        <div>
          {{ $vuetify.breakpoint.width > 480 ? 'Скидки/бонусы: ' : 'Скидки: ' }}{{
            Math.max(Number(order.totalCost - order.totalPay) || 0, 0)
          }}
          {{ $store.state.user.city.country.currency }}
        </div>
        <div>
          К оплате: {{ order.totalPay ? order.totalPay : '0' }} {{ $store.state.user.city.country.currency }}
        </div>
        <v-row v-if="0" style="white-space: nowrap">
          <v-col>
            {{ $vuetify.breakpoint.width > 480 ? 'Тип уборки: ' : '' }}{{
              cleanTypes.find(el => el.value === order.type).label
            }}
          </v-col>
          <v-col>{{ $vuetify.breakpoint.width > 480 ? 'Кол-во комнат: ' : '' }}{{
              rooms.find(el => el.value === order.address.flattype) ? rooms.find(el => el.value === order.address.flattype).label : rooms[0].label
            }} комн.
          </v-col>
          <v-col>{{ $vuetify.breakpoint.width > 480 ? 'Доп. услуги: ' : 'Доп: ' }}
            {{
              order.additionServices && parseInt(order.totalCost) ? parseInt(Math.round(order.totalCost - order.additionServices.clean || 0)) : 0
            }}
            {{ $store.state.user.city.country.currency }}
          </v-col>
          <v-col>{{ $vuetify.breakpoint.width > 480 ? 'Сумма заказа: ' : 'Сум: ' }}{{ parseInt(order.totalCost) || 0 }}
            {{
              $store.state.user.city.country.currency
            }}
          </v-col>
          <v-col v-if="$vuetify.breakpoint.width > 480">
            {{ $vuetify.breakpoint.width > 480 ? 'Скидки/бонусы: ' : 'Скидки: ' }}{{
              Math.max(Number(order.totalCost - order.totalPay) || 0, 0)
            }}
            {{ $store.state.user.city.country.currency }}
          </v-col>
          <v-col v-if="$vuetify.breakpoint.width > 480">К оплате: {{ order.totalPay ? order.totalPay : '0' }}
            {{ $store.state.user.city.country.currency }}
          </v-col>
          <v-col v-if="$vuetify.breakpoint.width < 480" class="mobile-col-basis"
                 style="padding-top: 0 !important; padding-bottom: 0 !important;">
            <v-row>
              <v-col>{{ $vuetify.breakpoint.width > 480 ? 'Скидки/бонусы: ' : 'Скидки: ' }}{{
                  parseInt(order.totalCost - order.totalPay) || 0
                }}
                {{ $store.state.user.city.country.currency }}
              </v-col>
              <v-col>К оплате: {{ order.totalPay ? order.totalPay : '0' }} {{ $store.state.user.city.country.currency }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Remove from "@/views/orders/modals/Remove";
import Problem from "@/views/orders/modals/Problem";
import Payment from "@/views/orders/modals/Payment";
import Cleaners from "@/views/orders/modals/Cleaners";
import Review from "@/views/orders/modals/Review";
import History from "@/views/orders/modals/History";
import HouseMap from "@/views/orders/modals/HouseMap";
import ToOtherCity from "@/views/orders/modals/ToOtherCity";
import {PhotoSwipeGallery} from 'v-photoswipe'
import InfoTip from "../../components/InfoTip";
import Act from "./modals/Act";
import AdvSettings from "./modals/AdvSettings";
import CleanerSlotsUsage from "./components/CleanerSlotsUsage";
import OrderToLead from "./modals/OrderToLead";

export default {
  name: "editOrder",
  components: {
    OrderToLead,
    CleanerSlotsUsage,
    AdvSettings,
    Act,
    InfoTip,
    Remove, Problem, Payment, Cleaners, Review, History,
    HouseMap, ToOtherCity,
    'v-photoswipe-gallery': PhotoSwipeGallery,
  },
  data() {
    return {
      loading_save: false,
      loading_reorder: false,
      loading_save_exit: false,
      loading_street: false,
      loading_phones: false,
      searchStreets: null,
      searchPhones: null,
      phones: [],
      valid: true,
      menu: false,
      rooms: [
        {value: "1", label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5+'},
      ],
      cleanTypes: [
        {value: 'standart', label: 'Быстрая'},
        {value: 'general', label: 'Генеральная'},
        {value: 'remont', label: 'После ремонта'},
        {value: 'other', label: 'Другое'},
      ],
      regularTypes: [
        {value: '0', name: '---'},
        {value: '2', name: 'Раз в месяц'},
        {value: '3', name: 'Раз в 2 недели'},
        {value: '4', name: 'Раз в неделю'},
      ],
      dry_clean_services: {
        check_services: [
          {
            title: 'Диван (мест)',
            num: 'himchistka_divana',
            leatherValue: 'him_d_k',
            animalValue: 'him_d_j',
          },
          {
            title: 'Кресло (шт)',
            num: 'himchistka_kresla',
            leatherValue: 'him_k_k',
            animalValue: 'him_k_j',
          },
          {
            title: 'Стулья (шт)',
            num: 'himchistka_stuliev',
            leatherValue: 'him_s_k',
            animalValue: 'him_s_j',
          },
          {
            title: 'Ковер/Палас (кв м)',
            num: 'himchistka_kovrov',
            leatherValue: 'him_p_k',
            animalValue: 'him_p_j',
          }
        ],
      },
      comments: {
        comment: {
          label: 'Комментарии к заказу',
          disabled: false
        },
        comment_client: {
          label: 'Коммент клиента (видно клиенту)',
          disabled: true
        }
      },
      discounts: {
        discount: {label: 'Скидка'},
        discountWindow: {label: 'Доп скидка на мытьё окон',},
        discountHolod: {label: 'Дополнительная скидка на холодильник'},
        discountDuhov: {label: 'Дополнительная скидка на духовки'},
        discountBal: {label: 'Доп скидка по балконам'},
        discountLustra: {label: 'Доп скидка по люстрам'},
        discountGlad1: {label: 'Доп скидка по глажке'},
        discountGlad2: {label: 'Доп скидка по замене белья'},
        discountGlad3: {label: 'Доп скидка по особым поручениям'},
        discountParogen: {label: 'Доп скидка по парогенератору'},
        discountGetKey: {label: 'Доп скидка по заехать за ключами'},
      },
      order_id: this.$route.params.order_id,
      copyOrder: this.$route.query.copyOrder,
      tasks: [],
      order: {
        address: {flattype: '1', 'wcNum': 1},
        datetimecleanObj: {},
        additionServices: {},
        client: {},
        services: [],
        type: 'standart',
        datetime_clean_startObj: {},
        datetime_clean_endObj: {},
      },
      initialOrder: {},
      advs: [],
      addresses: [],
      addressesOpt: [],
      streets: [],
      orderStats: null,
      lastFeedback: null,
      koefManual: 0,
      paymentLinkModalOpened: false,
      copied: false,
      mask: this.$store.state.setMask(''),
      chosenCleaners: [],
      paymentTypes: [
        {label: 'Банковской картой', value: '1'},
        {label: 'Наличные', value: '2'},
        {label: 'Оплата по счету БН', value: '3'}],
      cleaners: [],
      street: '',
      calcRequestNum: 0,
      loading_checkout: false,
      slotsInfo: {time_stats: {}, coefs: {}},
      isOpen: false,
      isOpenGallery: false,
      options: {
        index: 0
      },
      optionsGallery: {
        history: false,
        shareEl: false,
        fullscreenEl: false,
      },
      items: [],
      bottomSheet: false,
      calcData: {}
    }
  },
  methods: {
    openToolbarModal(type) {
      const modal = this.$refs[type]
      modal.order_id = this.order_id
      if (type === 'pay') {
        modal.paymentType = this.order.paymentType;
        this.updateOrder(() => this.loading_save = false);
      } else if (type === 'houseMap') {
        modal.address_id = this.order.address.n_id;
      } else if (type === 'remove') {
        modal.order = this.order;
      }

      modal.open(type === 'review' ? this.order : null)
    },
    openUser(id) {
      const modal = this.$refs.userModal
      modal.client_id = id
      modal.open()
    },
    copyLink() {
      let input = this.$refs.copyLink.$el.getElementsByTagName('input')[0];
      input.focus();
      input.select();
      this.copied = false;
      try {
        this.copied = window.document.execCommand('copy')
      } catch (e) {
        this.copied = false;
      }
      if (this.copied) setTimeout(() => this.copied = false, 2000);
    },
    calcHimchistkaCheckbox() {
      if (+this.order.himchistka) return;
      for (let service in this.$store.state.user.services.dry) {
        if (this.order.services.findIndex(s => s.name === service) > -1) {
          this.order.himchistka = '1';
          return;
        }
      }
    },
    setOrderData(data) {
      if (!data.response || !data.response.n_id) {
        return;
      }
      this.order = data.response;
      this.initialOrder = {...data.response};
      if (!parseInt(this.order.paymentType)) this.order.paymentType = '2';
      for (let key in this.order) {
        if (this.order[key] === '0' && key !== 'orderStatus') this.order[key] = ''
      }
      for (var i in this.order.cleanerPhotos) {
        this.order.cleanerPhotos[i] = {
          src: this.order.cleanerPhotos[i],
          w: 0,
          h: 0,
          title: '',
        }
      }
      this.findByPhone();
      if (data.response.address.street) this.streets.push({street: data.response.address.street})
      if (!this.order.type) this.order.type = this.cleanTypes[0].value;
      this.calcHimchistkaCheckbox();
    },
    getOrderData(callback = null) {
      this.$store.state.server.request(`order/get/${this.order_id}`, {}, (data) => {
        if (!data.response) {
          let error = data.error === 'Not found' ? 'Заказ не найден' : 'Не получилось открыть заказ';
          this.$root.notify(error, 'error');
          this.$router.push('/orders');
        } else {
          this.setOrderData(data);
          if (!(data.response.datetime_open * 1) && this.order_id) this.$store.state.server.request(`order/firstOpen/${this.order_id}`, {}, (data) => {
          });
          if (callback) callback();
        }
      })
    },
    cancelPayment() {
      if (!confirm('Отменить оплату?')) return;
      this.$store.state.server.request(`order/cancelPayment/${this.order_id}`, {}, (data) => {
        this.setOrderData(data);
      }, data => this.$root.notify(data.error || 'Нет соединения', 'error'))
    },
    findClientByPhone(tel) {
      if (!tel) return;
      this.loading_phones = true
      this.$store.state.server.request('client/find', {phone: tel}, (data) => {
        this.phones = data.response;
        this.phones.forEach(el => {
          el.phone_name = el.phone + ' ' + el.name;
        })
        this.loading_phones = false
      })
    },
    loadTasks() {
      this.$store.state.server.request('order/tasks/' + this.order_id, {}, (data) => {
        this.tasks = data.response;
      });
    },
    findByPhone() {
      if (!this.order.client.phone) return;
      this.$store.state.server.request('order/findByPhone', {phone: this.order.client.phone}, (data) => {
        if (!data.response.client) {
          this.order.client = {phone: this.order.client.phone, name: this.order.client.name};
          this.orderStats = null;
          this.lastFeedback = null;
          this.selectAddress(0);
        } else {
          this.order.client = data.response.client;
          this.orderStats = data.response.orderStats;
          this.lastFeedback = data.response.lastFeedback;
          this.addresses = data.response.addresses;
          if (data.response.addresses && data.response.addresses.length && !(this.order.address && this.order.address.n_id)) {
            this.selectAddress(data.response.addresses[0].n_id)
            // this.order.address = this.order.address ? this.order.address : data.response.addresses[0];

            // this.streets = [];
            // this.streets.push({street: this.order.address ? this.order.address.street : data.response.addresses[0]})
          }
          // for (let i = 0; i < data.response.adv.length; i++) {
          //   this.advs.push({label: data.response.adv[i].title, value: data.response.adv[i].n_id})
          // }
          this.addressesOpt = []
          this.addresses.forEach(addr => {
            if (!parseInt(addr.region)) addr.region = '1';
            let region = this.$store.state.user.regions.find(el => el.n_id === addr.region)
            // if (!region) console.log({region});
            let address = `${region.title} ${addr.street} ${addr.house}, кв ${addr.flat} ${addr.stats.housemap ? '(С картой дома)' : ''}`
            this.addressesOpt.push({label: address, value: addr.n_id})
          })
        }
        if (this.order.utm_source && !+this.order.client.adv && this.$store.state.user.adv.find(adv => adv.utm === this.order.utm_source)) {
          this.order.client.adv = this.$store.state.user.adv.find(adv => adv.utm === this.order.utm_source).n_id;
        }
      })
    },
    selectAddress(selectedAddress) {
      if (parseInt(selectedAddress)) {
        this.order.address = {...this.addresses.find(address => address.n_id === selectedAddress)};
        if (this.order.address.wcNum === '0') this.order.address.wcNum = '1'
        this.streets = [];
        if (this.order.address.street) this.streets.push({street: this.order.address.street})
      } else {
        this.order.address = {
          region: this.$store.state.user.regions[0].n_id,
          n_id: '0',
          flattype: this.order.address.flattype * 1 ? this.order.address.flattype : '1'
        }
      }
      this.calculate();
    },
    findStreet(str) {
      this.loading = true
      this.streets = [{street: str}].concat(this.streets);

      this.$store.state.server.request('order/findStreet', {region_id: this.order.address.region, q: str}, (data) => {
        this.streets = [{street: str}].concat(data.response);
        this.loading = false
      })
    },
    openCleaners() {
      let data = {};
      data.chosenCleaners = this.order.cleaners;
      data.datetimecleanObj = this.order.datetimecleanObj;
      data.clean_type = this.order.type;
      data.order_id = this.order.n_id;
      data.order = this.order;
      this.$refs.cleaners.open(data);
    },
    sendNotification() {
      this.updateOrder(() => {
        this.loading_save = !this.loading_save;
        this.request(`order/sendNotifications/${this.order_id}`, {}, 'Уведомления клинерам отправлены')
      });
    },
    updateOrder(callback = null) {
      // return;
      if (!parseInt(this.order.n_id) && parseInt(this.order_id)) {
        this.$root.notify('Заказ еще не загрузился, подождите', 'error')
        return;
      }
      if (this.order.client.adv == 4 && !this.order.client.text) return this.$root.notify('Укажите в О клиенте источник рекламы для варианта "другое"', 'error')
      if (this.$refs.form.validate()) {
        let params = this.prepareParams();
        if (!params.datetimecleanObj.date || !params.datetimecleanObj.time) {
          try {
            this.$refs.menu.scrollIntoView({behavior: 'smooth', block: 'center'});
          } catch (e) {

          }
          return this.$root.notify('Укажите дату и время уборки', 'error');
        }
        this.loading_save = true;
        if (this.order_id) {
          this.request(`order/update/${this.order_id}`, params, 'Заказ сохранён', () => {
            if (callback) callback();
            this.koefManual = 0;
          })
        } else {
          this.request(`order/create`, params, 'Заказ сохранен', (data) => {
            this.loading_save = false;
            this.loading_save_exit = false;
            this.$router.push(`/orders/${data.response.n_id}`)
          })
        }
      } else {
        this.$root.notify('Заполните все обязательные поля', 'error');
      }
    },
    request(method, params, successText, callback = null) {
      this.$store.state.server.request(method, params, (data) => {
        if (data.success) {
          this.$root.notify(successText);
          this.setOrderData(data)
          if (typeof callback === 'function') callback(data);
        } else {
          if (data && data.error) this.$root.notify(data.error, 'error')
        }
        this.loading_save = false;
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
      });
    },
    setTime(time) {
      this.$set(this.order.datetimecleanObj, 'time', time);
      this.calculate()
    },
    updateSlots() {
      this.$store.state.server.request('order/daySlots', {date: this.order.datetimecleanObj.date}, (data) => {
        this.slotsInfo = data.response;
      });
    },
    reorder() {
      if (!confirm('Создать переуборку к этому заказу?')) return;
      this.loading_reorder = true;
      this.$store.state.server.request('order/reorder/' + this.order_id, {}, (data) => {
        this.loading_reorder = false;
        this.$root.notify('Переуборка создана');
        this.$router.push(`/orders/${data.response}`);
      }, (data) => {
        this.loading_reorder = false;
        if (!data || !data.error) data = {error: 'Не получилось создать переуборку'}
        this.$root.notify(data.error, 'error')
      });
    },
    prepareParams() {
      if ((+this.$store.state.user.city.id) === 1) {
        // console.log('prepareParams', this.order.delivery, +this.order.pilesos, +this.order.delivery);
        // if (+this.order.pilesos) this.$set(this.order, 'delivery', '1');
        // if (+this.order.stremyanka) this.$set(this.order, 'delivery', '1');
      } else {
        // if (+this.order.pilesos) this.$set(this.order, 'delivery', '1');
        // if (+this.order.stremyanka) this.$set(this.order, 'delivery', '1');
        // if (+this.order.parogen) this.$set(this.order, 'delivery', '1');
      }
      let params = {...this.order};
      params.cleaners = [];
      if (this.order.cleaners && this.order.cleaners.length) {
        this.order.cleaners.forEach((cleaner, index) => {
          params.cleaners.push({n_id: cleaner.n_id});
        });
      }
      if (!params.cleaners.length) params.cleaners = '';
      console.log({...params});
      if (!this.koefManual) delete params.koefFixed
      delete params.price;
      delete params.cleanerPhotos;
      delete params.additionServices;
      return params;
    },
    getMask(phone) {
      if (!this.mask) this.mask = this.$store.state.setMask(phone);
      if (!phone) return this.mask;
      let mask = this.$store.state.setMask(phone);
      this.mask = mask;
      console.log({mask, phone});
      return mask;
    },
    checkboxClicked(field) {
      if (!['tul', 'klg'].includes(this.$store.state.user.city.code) && ['pilesos', 'stremyanka'].includes(field) && +this.order[field]) this.$set(this.order, 'delivery', '1');
    },
    serviceObject(fieldName) {
      let obj = this.order.services.find(s => s.name === fieldName) || {
        count: 0,
        name: fieldName,
      };
      // if (!obj) {
      //   obj = {
      //     count: 0,
      //     name: fieldName,
      //   }
      //   this.order.services.push(obj);
      // }
      obj.count = this.$root.intval(obj.count);
      return obj;
    },
    setServiceObject(fieldName, value, name = 'count') {
      let service = this.serviceObject(fieldName);
      let initVal = service.count;
      service[name] = value;
      if (name === 'count') {
        if (!initVal && +value) this.order.services.push(service);
        else if (initVal && !(+value)) this.order.services.splice(this.order.services.findIndex(s => s.name === fieldName), 1);
      }
      // this.order.services[fieldName] = service;
      this.calculate();
    },
    calculate($event) {
      if (+this.order.orderStatus === 5) return;
      this.calcRequestNum++;
      let requestNum = this.calcRequestNum;
      this.$store.state.server.request('order/calculate/', this.prepareParams(), (data) => {
        if (requestNum < this.calcRequestNum) return;
        for (let key in data.response) {
          this.order[key] = data.response[key]
        }
        this.calcData = data.response;
        this.order.koefFixed = this.order.koef;
        this.$forceUpdate()
      })
    },
    applyPromo() {
      if (this.calcData.promoInfo) {
        if (this.calcData.promoInfo.discount_sum)
          this.order.discountPromo = this.calcData.promoInfo.discount_sum;
        else if (this.calcData.promoInfo.discount_percent)
          this.order.discount= this.calcData.promoInfo.discount_percent;
        this.calculate();
      }
    },
    loadMetro() {
      let region = this.$store.state.user.regions.find(el => el.n_id == this.order.address.region);
      this.$store.state.server.request('order/metro2', {address: region.title + ' ' + this.order.address.street + ' ' + (this.order.address.house || '')}, (data) => {
        for (let i in data.response) {
          this.order.address[i] = data.response[i];
        }
      });
    },
    confirm() {
      this.request(`order/confirm/${this.order_id}`, {}, 'Заказ подтвержден')
    },
    problemSolve() {
      this.request(`order/problemSolved/${this.order_id}`, {}, 'Проблема решена')
    },
    setWaiting() {
      this.request(`order/waiting/${this.order_id}`, {value: 1}, 'Заказ поставлен на ожидание')
    },
    unsetWaiting() {
      this.request(`order/waiting/${this.order_id}`, {value: 0}, 'Заказ снят с ожидания')
    },
    returnFromTrash() {
      this.request(`order/returnFromTrash/${this.order_id}`, {}, 'Заказ возвращен из корзины')
    },
    onDryCleaningChange() {
      if (+this.order.himchistka && !this.$store.state.user.dryWithoutDelivery) {
        this.order.delivery = '1';
      } else if (!+this.order.himchistka) {
        this.order.services = [];
      }
      this.calculate();
    }
  },
  mounted() {
    if (this.order_id && +this.copyOrder) {
      this.getOrderData(() => {
        let unset = ['n_id', 'cleanerPhotos', 'datetimeObj', 'datetimeclean', 'feedback', 'koef', 'manager', 'orderStatus', 'orderStatusString', 'paymentLink', 'price', 'pricing_id', 'promo'];
        unset.forEach(field => delete this.order[field]);
        this.order.cleaners = [];
        this.order.datetimecleanObj = {};
        this.order.datetime_clean_startObj = {};
        this.order.datetime_clean_endObj = {};
        this.calculate();
      });
      this.order_id = 0;
    } else if (this.order_id) {
      this.findClientByPhone(this.order.client.phone)
      this.getOrderData(() => {
        this.calculate();
      });
      this.loadTasks();
      this.$eventBus.$on('editTask', () => {
        this.loadTasks();
      })
      this.$eventBus.$on('orderPayed', response => {
        if (response) {
          for (let i in response) {
            if (['client', 'address'].includes(i)) continue;
            this.order[i] = response[i];
          }
          this.$root.notify('Заказ успешно оплачен и закрыт!');
          this.$router.push('/orders');
        }
      })
    } else {
      if (this.$route.params.phone) {
        if (this.$route.params.phone[0] !== '+') this.$route.params.phone = '+' + this.$route.params.phone;
        this.order.client.phone = this.$route.params.phone;
      }
      if (this.$route.query.source_from) this.order.source_from = this.$route.query.source_from;
      this.findByPhone()
      this.order.paymentType = this.order.paymentType ? this.order.paymentType : '2';
      // this.$refs.dryCleanServices.style.pointerEvents = 'none'
      // this.$refs.dryCleanServices.style.opacity = '0.5'
    }
  },
  destroyed() {
    this.$eventBus.$off('orderPayed');
    this.$eventBus.$off('editTask');
    this.$eventBus.$off('orderProblemSaved');
    this.$eventBus.$off('otherCity');
    this.$eventBus.$off('setOrderCleaners');
  },
  computed: {
    // himchistka() {
    //   return this.order.himchistka;
    // },
  },
  watch: {
    searchStreets(val) {
      val && val !== this.order.address.street && this.findStreet(val)
    },
    searchPhones(val) {
      val && this.findClientByPhone(val)
    },
    dialog(visible) {
      visible ? this.getCleaners() : ''
    },
    // himchistka(val) {
    //   if (val === '0' || val === '') {
    //     this.$refs.dryCleanServices.style.pointerEvents = 'none'
    //     this.$refs.dryCleanServices.style.opacity = '0.5'
    //     let him_services = ['himchistka_divana', 'him_d_k', 'him_d_j', 'himchistka_kresla', 'him_k_k', 'him_k_j', 'himchistka_stuliev', 'him_s_k', 'him_s_j', 'himchistka_kovrov', 'him_p_k', 'him_p_j'];
    //     him_services.forEach((name) => {
    //       this.order[name] = ''
    //     })
    //   }
    //   if (val === '1') {
    //     this.$refs.dryCleanServices.style.pointerEvents = 'auto'
    //     this.$refs.dryCleanServices.style.opacity = '1'
    //   }
    // },
    // '$store.state.orderCleaners': function (cleaners) {
    //   this.order.cleaners = cleaners;
    // }
  },
  created() {
    this.$eventBus.$on('orderProblemSaved', data => {
      if (data) {
        this.getOrderData()
      }
    })
    this.$eventBus.$on('setOrderCleaners', cleaners => {
      this.order.cleaners = cleaners;
    })
    this.$eventBus.$on('otherCity', (data) => {
      this.order = data;
    })
  }
}
</script>
